import React from 'react'
import style from './Select.module.css'

function Select(props) {
  const { label, id, error, options, ...rest } = props
  return (
    <div className={style.group}>
      <label className={style.label} htmlFor={id}>{label}</label>
      <select className={style.select} {...rest}>
        {options}
      </select>
      {error && <small className={style.error}>{error}</small>}
    </div>
  )
}

export default Select
