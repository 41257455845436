import React from "react"
import HitlistPresentation from "../components/HitlistPresentation/HitlistPresentation"
import Section from "../components/Section"
import Heading from "../components/Typography/Heading"
import Text from "../components/Typography/Text"
import Divider from "../components/Divider"
import Footer from "../components/Footer"
import Container from "../components/Layout/Container"
import StoreButtons from "../components/StoreButtons"
import CookieConsent from "../components/CookieConsent"
import SEO from "../components/seo"

import FormInput from "../components/Form/Input"
import FormSelect from "../components/Form/Select"

import HitlistExplanation from "../images/priznaky_hitlist.png"
import Button from "../components/Button"

import Card from "../components/Card/Card"
import Row from "../components/Layout/Row"

import UndrawAgreement from "../images/undraw_agreement.svg"
import UndrawContactUs from "../images/undraw_contact_us.svg"
import UndrawConversation from "../images/undraw_conversation.svg"
import HitlistLogo from "../images/hitlist-logo.svg"
import VsLogo from "../images/vyzivove-studio-logo.png"
import TogetherLogos from "../images/togetherlogos.jpg"
import Padding from "../components/Layout/Padding"

import "../components/vyzivovy-poradca.css"

const Notice = () => {
  return (
    <div
      style={{
        background: "#e2612f",
        boxSizing: "border-box",
        padding: "25px 25px",
        margin: "25px 0",
        width: "100%",
        height: "800px",
        width: "100%",
        height: "100%",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontFamily: "Raleway",
        fontSize: "30px",
        textAlign: "center",
      }}
    >
      Konzultácie s výživovým poradcom <br /> sú momentálne nedostupné. <br />
      <br />
      Ďakujeme za pochopenie.
    </div>
  )
}

class PoradcaPage extends React.Component {
  state = {
    emailFormFeedback: null,
    emailFormInput: "@",
    emailFormName: "",
    emailFormSelect: null,
    emailFormLegalBox: false,
  }

  /**
   * Sends email by staticforms.xyz
   * @param {*} formId - Self explanatory I think
   */
  sendEmail() {
    const EMAIL_ENDPOINT =
      "https://europe-west1-fisherio-umbrella.cloudfunctions.net/hitlist-email-form-handler"

    const message = [
      `   email: ${this.state.emailFormInput}`,
      `    name: ${this.state.emailFormName}`,
      `problems: ${this.state.emailFormSelect}`,
    ]

    fetch(EMAIL_ENDPOINT, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(
        { subject: "Výživové poradenstvo", message: message.join("\n") },
        null,
        4
      ),
    })
      .then(res => res.json())
      .then(_ => {
        this.setState({
          emailFormFeedback:
            "Správa bola úspešne odoslaná, ozveme sa Vám do 24h na Vami uvedený email.",
        })
      })
      .catch(_ => {
        this.setState({
          emailFormFeedback: "Správu sa nepodarilo odoslať, skúste to znova.",
        })
      })
  }

  render() {
    return (
      <div>
        <SEO title={"Hitlist | Poradenstvo s odborníkom"} />
        <Container wide>
          <Notice />
          <Padding style={{ background: "red" }}>
            <div>
              <div>
                <img src={TogetherLogos} className="togetherLogos" />
              </div>
              {/* <div>
                <img src={HitlistLogo} />
              </div>
              <div style={{ fontSize: '40px'}}>
                +
              </div>
              <div>
                <img src={VsLogo} />
              </div> */}
            </div>
            <Text
              centered
              style={{
                fontSize: "22px",
                fontFamily: "Open Sans",
                margin: "32px 0 16px 0",
              }}
            >
              Cesta k liečbe histamínovej intolerancie môže viesť aj cez
              spoluprácu so skúseným výživovým poradcom, ktorý vám pomôže
              nastaviť jedálniček tak, aby bol nutrične vyvážený a hlavne
              zostavený z potravín, ktoré tolerujete. Preto sme spojili sily s
              Výživovým Štúdiom, ktoré už 8 rokov pomáha ľuďom s nastavením
              stravy a odstránením zdravotných problémov.
            </Text>
          </Padding>
        </Container>
        <Container wide>
          <Row
            style={{
              alignItems: "center",
              padding: "8px",
              alignItems: "stretch",
            }}
          >
            <Card
              style={{
                flex: 1,
                display: "flex",
                flexFlow: "column",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "45px",
                  marginBottom: "16px",
                  fontFamily: "Open Sans",
                }}
              >
                1.
              </div>
              <p
                style={{
                  fontSize: "17px",
                  fontFamily: "Open Sans",
                  textAlign: "center",
                }}
              >
                Kontaktujte výživového poradcu cez dole uvedený formulár
              </p>
              <img
                src={UndrawContactUs}
                style={{
                  maxWidth: "250px",
                  margin: "auto auto 0 auto",
                  display: "block",
                }}
              />
            </Card>
            <Card
              style={{
                flex: 1,
                display: "flex",
                flexFlow: "column",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "45px",
                  marginBottom: "16px",
                  fontFamily: "Open Sans",
                }}
              >
                2.
              </div>
              <p
                style={{
                  fontSize: "17px",
                  fontFamily: "Open Sans",
                  textAlign: "center",
                }}
              >
                Vďaka Hitlistu máte ZADARMO úvodnú online konzultáciu, na ktorej
                sa dozviete podrobnejšie infomácie.
              </p>
              <img
                src={UndrawConversation}
                style={{
                  maxWidth: "250px",
                  margin: "auto auto 0 auto",
                  display: "block",
                }}
              />
            </Card>
            <Card
              style={{
                flex: 1,
                display: "flex",
                flexFlow: "column",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "45px",
                  marginBottom: "16px",
                  fontFamily: "Open Sans",
                }}
              >
                3.
              </div>
              <p
                style={{
                  fontSize: "17px",
                  fontFamily: "Open Sans",
                  textAlign: "center",
                }}
              >
                Po dohode s výživovým poradcom získate na mieru vypracovaný
                výživový plán a pravidelné konzultácie.
              </p>
              <img
                src={UndrawAgreement}
                style={{
                  maxWidth: "250px",
                  margin: "auto auto 0 auto",
                  display: "block",
                }}
              />
            </Card>
          </Row>
        </Container>
        <Container>
          <main style={{ position: "relative" }}>
            <Notice />
            <div style={{ opacity: 0.25 }}>
              <Section
                title={
                  <>
                    <Heading centered headingSize={1}>
                      Poraď sa s odborníkom
                    </Heading>
                  </>
                }
                body={
                  <div style={{ position: "relative" }}>
                    <form id="emailForm">
                      <input
                        type="hidden"
                        name="accessKey"
                        value="3d4662a9-6d14-4f4b-98eb-ff308445706d"
                      ></input>
                      <input
                        type="text"
                        name="honeypot"
                        style={{ display: "none" }}
                      />

                      <FormInput
                        label={"Meno a Priezvisko"}
                        placeholder="Meno Priezvisko"
                        id={"trigger"}
                        value={this.state.emailFormName}
                        onChange={e =>
                          this.setState({ emailFormName: e.target.value })
                        }
                        name="meno"
                      />

                      <FormInput
                        label={"Email"}
                        id={"trigger"}
                        type="email"
                        value={this.state.emailFormInput}
                        onChange={e =>
                          this.setState({ emailFormInput: e.target.value })
                        }
                        placeholder="Vaša emailová adresa"
                        name="email"
                      />

                      <FormSelect
                        label={"Aké intolerancie máte?"}
                        id={"helpwith"}
                        name="ma-Intoleranciu"
                        onChange={e =>
                          this.setState({ emailFormSelect: e.target.value })
                        }
                        options={[
                          <option value key="opt0">
                            Vyberte kliknutím
                          </option>,
                          <option value="mam-hit" key="opt1">
                            Mám HIT
                          </option>,
                          <option value="mam-viac-intolerancii" key="opt2">
                            Mám viac intolerancií
                          </option>,
                        ]}
                      />
                      <div>
                        <input
                          type="checkbox"
                          value={this.state.emailFormLegalBox}
                          onChange={() =>
                            this.setState({
                              emailFormLegalBox: !this.state.emailFormLegalBox,
                            })
                          }
                          id="privacyPolicy"
                        />{" "}
                        <label htmlFor="privacyPolicy">
                          <Text
                            small
                            white
                            style={{ display: "inline", lineHeight: "1.5" }}
                          >
                            Súhlasím so{" "}
                            <a
                              href="https://fisherio.com/privacy-policy"
                              style={{ color: "#fff" }}
                            >
                              spracovaním osobných údajov.
                            </a>
                          </Text>
                        </label>
                      </div>

                      {/* <div style={{ marginTop: "16px" }}>
                        <Button
                          disabled
                          white
                          onClick={() => {
                            this.sendEmail()
                          }}
                          style={{ marginLeft: "auto", display: "block" }}
                        >
                          Chcem zdarma konzultáciu
                        </Button>
                      </div> */}

                      {this.state.emailFormFeedback && (
                        <Text centered white style={{ marginTop: "24px" }}>
                          {this.state.emailFormFeedback}
                        </Text>
                      )}
                    </form>
                  </div>
                }
              />
            </div>
          </main>
          <Footer />
        </Container>
      </div>
    )
  }
}
export default PoradcaPage
