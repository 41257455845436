import React from 'react'
import PropTypes from 'prop-types'
import styles from './Card.module.css'

function Card(props) {
  return (
    <div className={[styles.card, styles.spacing].join(" ")} {...props}>
      {props.children}
    </div>
  )
}

Card.propTypes = {

}

export default Card

