import React from 'react'
import style from './Input.module.css'

function Input(props) {
  const { label, id, error, ...rest } = props
  return (
    <div className={style.group}>
      <label className={style.label} htmlFor={id}>{label}</label>
      <input id={id} className={style.input} {...rest} />
      {error && <small className={style.error}>{error}</small>}
    </div>
  )
}

export default Input
